export default function GoogleIcon() {
    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5551 11.8147C21.5551 10.9407 21.4841 10.3029 21.3306 9.64148H11.3501V13.5864H17.2085C17.0904 14.5668 16.4526 16.0432 15.0352 17.0353L15.0153 17.1674L18.171 19.612L18.3896 19.6338C20.3975 17.7794 21.5551 15.051 21.5551 11.8147Z" fill="#4285F4" />
            <path d="M11.3501 22.208C14.2202 22.208 16.6297 21.2631 18.3896 19.6332L15.0352 17.0346C14.1375 17.6606 12.9327 18.0977 11.3501 18.0977C8.539 18.0977 6.15314 16.2433 5.30264 13.6803L5.17798 13.6909L1.89667 16.2303L1.85376 16.3496C3.60181 19.8221 7.19246 22.208 11.3501 22.208Z" fill="#34A853" />
            <path d="M5.30124 13.6812C5.07683 13.0198 4.94696 12.311 4.94696 11.5788C4.94696 10.8464 5.07683 10.1378 5.28944 9.47634L5.28349 9.33548L1.96107 6.75525L1.85236 6.80695C1.13191 8.24795 0.718506 9.86613 0.718506 11.5788C0.718506 13.2914 1.13191 14.9095 1.85236 16.3505L5.30124 13.6812Z" fill="#FBBC05" />
            <path d="M11.3501 5.0583C13.3461 5.0583 14.6926 5.92052 15.4604 6.64106L18.4604 3.71184C16.6179 1.99921 14.2202 0.947998 11.3501 0.947998C7.19246 0.947998 3.60181 3.33386 1.85376 6.80636L5.29083 9.47575C6.15314 6.9127 8.539 5.0583 11.3501 5.0583Z" fill="#EB4335" />
        </svg>
    );
}
